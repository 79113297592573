import { Checkbox, Select } from '@hexa-ui/components';
import { useDecision } from '@optimizely/react-sdk';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { DocumentTitle, TypeToast, useAppHeader, useToast } from 'admin-portal-shared-services';
import { useFormik } from 'formik';
import { cloneDeep } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import OperationSelect from '../../../../components/OperationSelect';
import { OPTIMIZELY_KEY_FRONTEND_LOCAL_MODEL } from '../../../../consts/optimizely';
import { QUERY_KEY_ACTIVITY_TASK_GROUPS } from '../../../../consts/query';
import { PATH_HOME } from '../../../../consts/route';
import useAppSelector from '../../../../hooks/useAppSelector';
import useGroupData from '../../../../hooks/useContext/useGroupData/useGroupData';
import { UserState } from '../../../../models/user';
import { preventFormSubmitOnEnter } from '../../../../utils/form';
import { createTaskGroup } from '../../api/services/taskGroup';
import FooterTaskGroupForm from '../../components/FooterTaskGroupForm';
import { QUERY_KEY_TASK_GROUP_CARD } from '../../consts/query';
import { PATH_BASE, PATH_CREATE } from '../../consts/route';
import { FormTypeId, PayloadTaskGroup, TaskGroupFormField } from '../../models/task-group';
import { taskGroupSchema } from '../../schemas/taskGroup';
import {
  CardTaskGroupForm,
  ContainerSelect,
  ContainerTaskGroupsCreate,
  FiltersContainer,
  FormTaskGroupsCreate,
  InputTaskGroupMaxTasks,
  InputTaskGroupName,
  TextAreaTaskGroupDescription,
  WrapperInputMaxTasks,
  WrapperLocalModelCheckBox,
} from './TaskGroupsCreate.styles';

const TaskGroupCreate = (): JSX.Element => {
  const { formatMessage } = useIntl();
  const [appHeaderConfig, setAppHeaderConfig] = useAppHeader();
  const { selectedCountry } = appHeaderConfig;
  const { profile: storedProfile } = useAppSelector<UserState>((state) => state.user);
  const queryClient = useQueryClient();
  const toastService = useToast();
  const navigate = useNavigate();
  const { availableProfiles } = useAppSelector<UserState>((state) => state.user);

  const { groupData } = useGroupData();
  const { organization, seller } = groupData;
  const [isFrontendLocalModelFlag, isFrontendLocalModelReady] = useDecision(
    OPTIMIZELY_KEY_FRONTEND_LOCAL_MODEL
  );
  const isLocalModelFlagEnabled = isFrontendLocalModelReady && isFrontendLocalModelFlag.enabled;

  const { isLoading: isLoadingCreateTaskGroup, mutate: mutateCreateTaskGroup } = useMutation(
    createTaskGroup,
    {
      onError: () => {
        toastService.notify({
          type: TypeToast.ERROR,
          message: formatMessage({ id: 'toast.error.task.create' }),
        });
      },
      onSuccess: (_, values) => {
        toastService.notify({
          type: TypeToast.SUCCESS,
          message: formatMessage({ id: 'toast.success.task.create' }),
        });
        queryClient.invalidateQueries([
          QUERY_KEY_TASK_GROUP_CARD,
          {
            country: selectedCountry,
            profileId: storedProfile?.id,
          },
        ]);
        queryClient.invalidateQueries([
          QUERY_KEY_ACTIVITY_TASK_GROUPS,
          {
            country: selectedCountry,
            profileId: storedProfile?.id,
          },
        ]);
        navigate(PATH_BASE + `?profile=${values.body.profileId}`);
      },
    }
  );

  function handleSubmitTask(payload: PayloadTaskGroup) {
    const updatedPayload = formatPayload(payload);

    mutateCreateTaskGroup({ body: updatedPayload });
  }

  function formatPayload(payload: PayloadTaskGroup) {
    const updatedPayload = cloneDeep(payload);
    return updatedPayload;
  }

  const { handleSubmit, handleChange, values, touched, errors, setFieldValue } = useFormik({
    initialValues: {
      [TaskGroupFormField.Profile]: storedProfile.id,
      [TaskGroupFormField.Name]: '',
      [TaskGroupFormField.Description]: '',
      [TaskGroupFormField.MaximumTasks]: undefined,
      [TaskGroupFormField.IsLocalModel]: false,
    } as PayloadTaskGroup,
    validationSchema: taskGroupSchema,
    onSubmit: handleSubmitTask,
    enableReinitialize: true,
  });
  const [isTaskGroupLocalModelChecked, setIsTaskGroupLocalModelChecked] = useState(
    values.isLocalModel
  );
  const hasErrorTaskGroupProfile = !!touched.profileId && !!errors.profileId;
  const hasErrorTaskGroupName = !!touched.name && !!errors.name;
  const hasErrorTaskGroupDescription = !!touched.description && !!errors.description;
  const hasErrorTaskGroupMaxTasks = !!touched.maxTasksPerVisit && !!errors.maxTasksPerVisit;
  const fieldRefs = useRef<Record<string, HTMLElement>>({});

  useEffect(() => {
    setAppHeaderConfig({
      pageTitle: formatMessage({ id: 'app.taskGroups.title.page.taskGroupCreate' }),
      breadcrumbConfig: {
        homePath: PATH_HOME,
        items: [
          {
            isCurrentPage: false,
            path: PATH_BASE,
            label: formatMessage({ id: 'app.taskGroups.title.page.taskGroupsList' }),
          },
          {
            isCurrentPage: true,
            path: PATH_CREATE,
            label: formatMessage({ id: 'app.taskGroups.title.page.taskGroupCreate' }),
          },
        ],
      },
    });
  }, [setAppHeaderConfig, formatMessage]);

  function onChangeProfile(value) {
    setFieldValue(TaskGroupFormField.Profile, value);
  }

  const handleChangeMaxNumbers = (event) => {
    const { value } = event.target;
    const intValue = parseInt(value, 10);

    if (isNaN(intValue) || intValue < 1) {
      setFieldValue(TaskGroupFormField.MaximumTasks, '');
    } else {
      const maxLength = 5;
      const slicedValue = parseInt(value.length > maxLength ? value.slice(0, maxLength) : value);
      setFieldValue(TaskGroupFormField.MaximumTasks, slicedValue);
    }
  };

  function setLocalModelHandler() {
    setIsTaskGroupLocalModelChecked(!isTaskGroupLocalModelChecked);
    setFieldValue(TaskGroupFormField.IsLocalModel, !isTaskGroupLocalModelChecked);
  }

  return (
    <>
      <DocumentTitle>
        {formatMessage({ id: 'app.taskGroups.title.page.taskGroupCreate' })}
      </DocumentTitle>
      <ContainerTaskGroupsCreate data-testid="container-task-group-create">
        <FiltersContainer>
          <OperationSelect
            labelId={formatMessage({ id: 'formField.label.task.list.selectOperation' })}
            organization={organization.name}
            seller={seller.name}
            disabled
          />
        </FiltersContainer>
        <FormTaskGroupsCreate
          onKeyDown={preventFormSubmitOnEnter}
          onSubmit={(event) => {
            event.preventDefault();
            handleSubmit(event);
          }}
        >
          <CardTaskGroupForm border={'small'} elevated={'minimal'}>
            <ContainerSelect data-testid="container-select-category">
              <Select.Root
                data-testid="select-profile"
                size="large"
                width={'100%'}
                placeholder={formatMessage({ id: 'app.taskGroups.fields.placeholder.profile' })}
                value={values.profileId}
                label={formatMessage({
                  id: 'app.taskGroups.fields.taskGroupForm.label.profile',
                })}
                onChange={(value) => onChangeProfile(value)}
                name={TaskGroupFormField.Profile}
                error={
                  hasErrorTaskGroupProfile && formatMessage({ id: 'formField.error.requiredField' })
                }
              >
                {availableProfiles.map((profile, index) => (
                  <Select.Option value={profile.id} key={index}>
                    {profile.title}
                  </Select.Option>
                ))}
              </Select.Root>
            </ContainerSelect>

            <InputTaskGroupName
              label={formatMessage({
                id: 'app.taskGroups.fields.taskGroupForm.label.name',
              })}
              data-testid="input-task-group-name"
              placeholder={formatMessage({ id: 'app.taskGroups.fields.placeholder.name' })}
              characterCounter
              width={'100%'}
              required
              name={TaskGroupFormField.Name}
              value={values.name}
              onChange={handleChange}
              maxLength={80}
              hasError={hasErrorTaskGroupName}
              errorText={formatMessage({ id: 'formField.error.requiredField' })}
            />

            <TextAreaTaskGroupDescription
              data-testid="input-task-group-description"
              label={formatMessage({
                id: 'app.taskGroups.fields.taskGroupForm.label.description',
              })}
              placeholder={formatMessage({
                id: 'app.taskGroups.fields.placeholder.description',
              })}
              width={'100%'}
              required
              name={TaskGroupFormField.Description}
              value={values.description}
              onChange={handleChange}
              hasError={hasErrorTaskGroupDescription}
              characterCounter
              maxLength={80}
              errorText={formatMessage({ id: 'formField.error.requiredField' })}
            />

            <WrapperInputMaxTasks>
              <InputTaskGroupMaxTasks
                label={formatMessage({
                  id: 'app.taskGroups.fields.taskGroupForm.label.maximumTasks',
                })}
                data-testid="input-task-group-max-tasks"
                placeholder={formatMessage({
                  id: 'app.taskGroups.fields.placeholder.maximumTasks',
                })}
                type={'number'}
                required
                name={TaskGroupFormField.MaximumTasks}
                value={values.maxTasksPerVisit}
                onChange={handleChangeMaxNumbers}
                hasError={hasErrorTaskGroupMaxTasks}
                errorText={formatMessage({ id: 'formField.error.requiredField' })}
                min={1}
                maxLength={5}
              />
            </WrapperInputMaxTasks>

            <WrapperLocalModelCheckBox>
              {isLocalModelFlagEnabled && (
                <Checkbox
                  ref={(ref) => {
                    fieldRefs.current[`${TaskGroupFormField.IsLocalModel}`] = ref;
                  }}
                  label={formatMessage({
                    id: 'app.taskGroups.fields.taskGroupForm.label.isLocalModel',
                  })}
                  id="local-model-checkbox"
                  data-testid="local-model-task-checkbox"
                  value={`${isTaskGroupLocalModelChecked}`}
                  onCheckedChange={setLocalModelHandler}
                  checked={values.isLocalModel}
                />
              )}
            </WrapperLocalModelCheckBox>
          </CardTaskGroupForm>
          <FooterTaskGroupForm isLoading={isLoadingCreateTaskGroup} formType={FormTypeId.Create} />
        </FormTaskGroupsCreate>
      </ContainerTaskGroupsCreate>
    </>
  );
};

export default TaskGroupCreate;
