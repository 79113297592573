import { cloneDeep } from 'lodash';
import { useEffect, useState } from 'react';
import { ImageRecognitionBucketId, ListFilters, TaskState, TaskType } from '../../models/task';
import {
  setAvailableTaskTypes,
  setInitialListFilters,
  setListFilters,
} from '../../redux/slices/taskSlice';
import useAppDispatch from '../useAppDispatch';
import useAppSelector from '../useAppSelector';
import useHasTaskScopes from '../useHasTaskScopes';

function useUserTaskScopes(): { isSuccess: boolean } {
  const { availableTaskTypes } = useAppSelector<TaskState>((state) => state.task);
  const dispatch = useAppDispatch();
  const taskScopes = useHasTaskScopes();

  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    const listFilters: ListFilters = {
      imageRecognition: ImageRecognitionBucketId.None,
      isSponsored: false,
    };

    dispatch(setInitialListFilters(listFilters));
    dispatch(setListFilters(listFilters));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let taskTypesAvailable: TaskType[];

    // eslint-disable-next-line prefer-const
    taskTypesAvailable = cloneDeep(availableTaskTypes);

    taskTypesAvailable.forEach((taskType) => {
      const hasTaskTypePermission = taskScopes[taskType.id].hasTaskTemplateWrite;
      if (!hasTaskTypePermission) {
        taskType.hasPermission = false;
      }
    });

    dispatch(setAvailableTaskTypes(taskTypesAvailable));
    setIsSuccess(true);
  }, []);

  return {
    isSuccess,
  };
}

export default useUserTaskScopes;
