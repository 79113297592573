import { Tooltip } from '@hexa-ui/components';
import React from 'react';
import { OptionProps } from 'react-select';
import { Styled } from '../../Autocomplete.styles';

function Option({
  children,
  innerProps,
  innerRef,
  isSelected,
  isFocused,
}: Readonly<Partial<OptionProps>>): React.JSX.Element {
  return (
    <Styled.AutoCompleteOption
      isSelected={isSelected}
      isFocused={isFocused}
      data-testid={`autocomplete-select-option-${children}`}
      {...innerProps}
      ref={innerRef}
    >
      <Tooltip placement="top" text={children as string}>
        {children}
      </Tooltip>
    </Styled.AutoCompleteOption>
  );
}

export default Option;
