import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import PhotoIcon from '../../assets/icons/photo.svg';
import SurveyIcon from '../../assets/icons/survey.svg';
import SimpleIcon from '../../assets/icons/task.svg';
import {
  AddMethod,
  Category,
  CategoryDeprecated,
  CategoryId,
  ImageRecognitionBucketId,
  ListFilters,
  SubTypeId,
  TaskState,
  TaskType,
  TaskTypeId,
} from '../../models/task';
import { ProfileId } from '../../models/user';

const initialState = {
  availableTaskTypes: [
    {
      id: TaskTypeId.Simple,
      icon: SimpleIcon,
      profiles: [ProfileId.Bdr, ProfileId.Merchandiser, ProfileId.NegBdr],
      categories: [
        CategoryId.CustomerSatisfaction,
        CategoryId.DigitalEducation,
        CategoryId.Execution,
        CategoryId.ValueCreation,
      ],
      hasPermission: true,
    },
    {
      id: TaskTypeId.Survey,
      icon: SurveyIcon,
      profiles: [ProfileId.Bdr, ProfileId.Merchandiser, ProfileId.NegBdr],
      categories: [
        CategoryId.CustomerSatisfaction,
        CategoryId.DigitalEducation,
        CategoryId.Execution,
        CategoryId.ValueCreation,
      ],
      hasPermission: true,
    },
    {
      id: TaskTypeId.Photo,
      icon: PhotoIcon,
      profiles: [ProfileId.Bdr, ProfileId.Merchandiser],
      categories: [CategoryId.Execution],
      hasPermission: true,
    },
  ],
  availableCategoriesDeprecated: undefined,
  availableCategories: undefined,
  availableSubTypesDeprecated: [
    {
      id: SubTypeId.Challenge,
      categories: [
        CategoryId.CustomerSatisfaction,
        CategoryId.DigitalEducation,
        CategoryId.Execution,
        CategoryId.ValueCreation,
      ],
      addMethods: [AddMethod.Manual],
    },
    {
      id: SubTypeId.Combo,
      categories: [
        CategoryId.CustomerSatisfaction,
        CategoryId.DigitalEducation,
        CategoryId.Execution,
        CategoryId.ValueCreation,
      ],
      addMethods: [AddMethod.Manual, AddMethod.Csv],
    },
    {
      id: SubTypeId.Coverage,
      categories: [CategoryId.ValueCreation],
      addMethods: [AddMethod.Manual, AddMethod.Csv],
    },
    {
      id: SubTypeId.Volume,
      categories: [CategoryId.ValueCreation],
      addMethods: [AddMethod.Manual, AddMethod.Csv],
    },
  ],
  availableImageRecognitionBucketsDeprecated: [
    {
      id: ImageRecognitionBucketId.Shelf,
      categories: [CategoryId.Execution],
    },
    {
      id: ImageRecognitionBucketId.Poster,
      categories: [CategoryId.Execution],
    },
    {
      id: ImageRecognitionBucketId.Cooler,
      categories: [CategoryId.Execution],
    },
  ],
  availableImageRecognitionBuckets: [
    {
      id: ImageRecognitionBucketId.Shelf,
    },
    {
      id: ImageRecognitionBucketId.Poster,
    },
    {
      id: ImageRecognitionBucketId.Cooler,
    },
  ],
  initialListFilters: undefined,
  listFilters: undefined,
} as TaskState;

export const taskSlice = createSlice({
  name: 'task',
  initialState,
  reducers: {
    setAvailableTaskTypes: (state: TaskState, action: PayloadAction<TaskType[]>) => {
      state.availableTaskTypes = action.payload;
    },
    setResetTaskStates: (state) => {
      state.availableTaskTypes = initialState.availableTaskTypes;
    },
    setAvailableCategoriesDeprecated: (
      state: TaskState,
      action: PayloadAction<CategoryDeprecated[]>
    ) => {
      state.availableCategoriesDeprecated = action.payload;
    },
    setAvailableCategories: (state: TaskState, action: PayloadAction<Category[]>) => {
      state.availableCategories = action.payload;
    },
    setInitialListFilters: (state: TaskState, action: PayloadAction<ListFilters>) => {
      state.initialListFilters = {
        ...state.initialListFilters,
        ...action.payload,
      };
    },
    setListFilters: (state: TaskState, action: PayloadAction<ListFilters>) => {
      state.listFilters = {
        ...state.listFilters,
        ...action.payload,
      };
    },
  },
});

export const {
  setAvailableTaskTypes,
  setAvailableCategoriesDeprecated,
  setAvailableCategories,
  setInitialListFilters,
  setListFilters,
  setResetTaskStates,
} = taskSlice.actions;

export default taskSlice.reducer;
