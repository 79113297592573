import { UserMetadata } from 'admin-portal-shared-services';
import { SubmissionStatusCount } from './submission';

export enum TaskTypeId {
  Simple = 'SIMPLE',
  Survey = 'SURVEY',
  Photo = 'IMAGE_RECOGNITION',
}

export enum DurationId {
  Fast = 'FAST',
  Medium = 'MEDIUM',
  Long = 'LONG',
  VeryLong = 'VERY_LONG',
  VeryFast = 'VERY_FAST',
  Empty = '',
}

export enum CategoryId {
  CustomerSatisfaction = 'CUSTOMER_SATISFACTION',
  DigitalEducation = 'DIGITAL_EDUCATION',
  Execution = 'EXECUTION',
  ValueCreation = 'VALUE_CREATION',
  PortfolioExpansion = 'PORTFOLIO_EXPANSION',
  VolumeRevenue = 'VOLUME_REVENUE',
  MarketplacePortfolioExpansion = 'MARKETPLACE_PORTFOLIO_EXPANSION',
  MarketplaceRevenue = 'MARKETPLACE_REVENUE',
  ExecutionAbi = 'EXECUTION_ABI',
  ExecutionMarketplace = 'EXECUTION_MARKETPLACE',
  MiNegocio = 'MI_NEGOCIO',
  FinTech = 'FINTECH',
  ChallengeAndRewards = 'CHALLENGE_REWARDS',
  OrderOnBEES = 'ORDER_BEES',
  DTaaS = 'DTAAS',
  SurveyDataCollection = 'SURVEY_DATA_COLLECTION',
  OtherTasks = 'OTHER_TASKS',
  Empty = '',
}

export enum SubTypeId {
  Challenge = 'CHALLENGE',
  Combo = 'COMBO',
  Coverage = 'MONTHLY_PRODUCT_COVERAGE',
  Volume = 'VOLUME',
  Empty = '',
}

export enum SubTypeIdPhoto {
  ImageRecognition = 'IMAGE_RECOGNITION',
}

export enum ImageRecognitionBucketId {
  Shelf = 'SHELF',
  Poster = 'POSTER',
  Cooler = 'COOLER',
  None = 'NONE',
  Empty = '',
}

export enum FormTypeId {
  Create = 'CREATE',
  Update = 'UPDATE',
  Duplicate = 'DUPLICATE',
}

export enum FrequencyId {
  EveryVisit = 'EVERY_VISIT',
  Monthly = 'MONTHLY',
  Effective = 'EVERY_VISIT_UNTIL_COMPLETED_OR_EFFECTIVE',
  Empty = '',
}

export enum SurveyQuestionResponseId {
  SingleSelect = 'SINGLESELECTION',
  FreeText = 'FREETEXT',
  Conditional = 'CONDITIONAL',
  Numeric = 'NUMERIC',
  MultipleSelect = 'MULTIPLESELECTION',
  Empty = '',
}
export enum AddMethod {
  Manual = 'MANUAL',
  Csv = 'CSV',
}

export enum Period {
  L15d = 'l15d',
  L30d = 'l30d',
  L60d = 'l60d',
  Lmth = 'lmth',
  Tmth = 'tmth',
  None = 'none',
}

export enum ActivityFormField {
  ActivityTemplateId = 'activityTemplateId',
  Name = 'name',
  ProfileId = 'profileId',
  IsSponsored = 'isSponsored',
  SponsoredBy = 'sponsoredBy',
  SponsoredById = 'sponsoredById',
  DurationInMinutes = 'durationInMinutes',
  FrequencyId = 'frequencyId',
  ActivityTemplateCategoryId = 'activityTemplateCategoryId',
  AudienceTemplate = 'audienceTemplate',
  EffectivenessTemplate = 'effectivenessTemplate',
  ActivityConfigurationPerProfile = 'activityConfigurationPerProfile',
  Message = 'message',
  ValidEffectiveness = 'validEffectiveness',
  InvalidEffectiveness = 'invalidEffectiveness',
  PendingEffectiveness = 'pendingEffectiveness',
  ActivityTypeId = 'activityTypeId',
  ActivitySubtypeId = 'activitySubtypeId',
  Tags = 'tags',
  CampaignId = 'campaignId',
  Priority = 'priority',
  RequiredDuration = 'requiredDuration',
  ActivityTemplateGroupId = 'activityTemplateGroupId',
  ModelId = 'modelId',
}

export enum TaskFormField {
  Name = 'name',
  TemplateTitle = 'templateTitle',
  Profile = 'profile',
  IsSponsored = 'isSponsored',
  SponsoredBy = 'sponsoredBy',
  SponsoredById = 'sponsoredById',
  Duration = 'duration',
  DurationInMinutes = 'durationInMinutes',
  Frequency = 'frequency',
  FrequencyId = 'frequencyId',
  Category = 'category',
  ActivityTemplateCategoryId = 'activityTemplateCategoryId',
  textEffectiveTask = 'textEffetiveTask',
  textNotEffetiveTask = 'textNotEffetiveTask',
  textPendingTask = 'textPendingTask',
  AudienceTemplate = 'audienceTemplate',
  EffectivenessTemplate = 'effectivenessTemplate',
  ConfigEffectiveness = 'configTaskEffectiveness',
  ActivityTemplateGroupId = 'activityTemplateGroupId',
}

export enum TaskFormFieldSimple {
  EffectivenessTemplate = 'effectivenessTemplate',
  AudienceTemplate = 'audienceTemplate',
}

export enum TaskFormFieldSurvey {
  Survey = 'survey',
}

export enum ActivityFormFieldPhoto {
  ImageRecognitionBucket = 'imageRecognitionBucket',
  ConfigEffectiveness = 'configTaskEffectiveness',
  VisionRuleId = 'visionRuleId',
}

export enum CommonSimpleFormField {
  Condition = 'condition',
  Feature = 'feature',
  Operator = 'operator',
  Value = 'value',
  Period = 'period',
  MinValue = 'minimumValue',
  MaxValue = 'maximumValue',
  RequiredPeriod = 'requiredPeriod',
  Skus = 'skus',
  MetadataIds = 'metadataIds',
  FeatureType = 'featureType',
}

export enum EffectivenessSimpleFormField {
  Condition = 'condition',
  Feature = 'feature',
  Operator = 'operator',
  Value = 'value',
  Period = 'period',
  MinValue = 'minimumValue',
  MaxValue = 'maximumValue',
  RequiredPeriod = 'requiredPeriod',
  Skus = 'skus',
  MetadataIds = 'metadataIds',
  FeatureType = 'featureType',
}

export enum AudienceFormField {
  Condition = 'condition',
  Feature = 'feature',
  Operator = 'operator',
  Value = 'value',
  MinValue = 'minimumValue',
  MaxValue = 'maximumValue',
  Period = 'period',
  RequiredPeriod = 'requiredPeriod',
  Skus = 'skus',
  MetadataIds = 'metadataIds',
  FeatureType = 'featureType',
}

export enum EffectivenessPhotoFormField {
  SubType = 'subType',
  VisionConfigId = 'visionConfigId',
}

export enum SubTaskOptionFormField {
  Id = 'id',
  Value = 'value',
  Jump = 'jump',
}

export enum SubTaskConditionalOptionFormField {
  Id = 'id',
  Position = 'position',
}

export enum SurveyQuestionaryFormField {
  Id = 'id',
  Title = 'title',
  IsRequired = 'isRequired',
  ResponseType = 'responseType',
  Options = 'options',
}

export enum SurveyQuestionaryError {
  HasErrorQuantity = 'field must have 2 items',
  HasDuplicatedFields = 'duplicated fields are not allowed',
}

export enum OriginScreenName {
  HomePage = 'homepage',
  Link = 'link',
}

export enum TriggerType {
  DragDrop = 'DRAG_DROP',
  Typing = 'TYPING',
  Null = 'null',
}

export enum TaskFilterType {
  NonSponsored = 'non-sponsored',
  Sponsored = 'sponsored',
}

export enum TaskFilterLabelId {
  NonSponsored = 'formField.label.task.list.selectTaskType.nonSponsored',
  Sponsored = 'formField.label.task.list.selectTaskType.sponsored',
}

export enum SearchParamNameTask {
  Profile = 'profile',
  Mode = 'mode',
  Page = 'page',
  Id = 'id',
  TaskGroupId = 'taskGroupId',
}

export enum SearchParamName {
  Page = 'page',
  Size = 'size',
}

export enum TaskTab {
  Submit = 'submit',
  Priority = 'priority',
}

export enum ListFilter {
  IsSponsored = 'isSponsored',
  ImageRecognition = 'imageRecognition',
}

export enum FilterTab {
  SimpleSurvey = 'SimpleSurvey',
  Photo = 'Photo',
  Sponsored = 'Sponsored',
}

export enum VolumeUnitId {
  Packs = 'PACKS',
  Hectolitres = 'HECTOLITRES',
}

export interface GetVisionJobId {
  visionJobId: string;
  signal?: AbortSignal;
}

export interface GetTask {
  activityId: string;
  taskId?: string;
  signal?: AbortSignal;
}

export interface GetTaskDurations {
  signal?: AbortSignal;
}

export interface CreateActivitySimple {
  body: PayloadActivitySimple;
  vendorGroupId?: string;
}

export interface CreateTaskSurvey {
  body: PayloadActivitySurvey;
}

export interface CreateActivityPhoto {
  body: PayloadActivityPhoto;
}

export interface CreateTaskPhoto {
  body: PayloadActivityPhoto;
}

export interface UpdateActivitySimple {
  activityId: string;
  vendorGroupId: string;
  body: PayloadActivitySimple;
}
export interface UpdateActivityPhoto {
  activityId: string;
  body: PayloadActivityPhoto;
}

export interface UpdateTaskSurvey {
  activityId: string;
  body: PayloadActivitySurvey;
}

export interface TaskList {
  content: Task[];
  pagination: { pageSize: number; totalElements: number; totalPages: number };
}

export interface VisionJobId {
  configId: string;
  description: string;
}

export interface TaskType {
  id: TaskTypeId;
  icon: string;
  profiles: string[];
  categories: CategoryId[];
  hasPermission: boolean;
}

export interface Task {
  activityConfigurationPerProfile: ActivityConfigurationPerProfile[];
  id: string;
  name: string;
  templateTitle: string;
  description?: string;
  taskType: TaskTypeId;
  category: CategoryId;
  priority: number | null;
  duration: {
    duration: DurationId;
    minutes: number;
  } | null;
  durationInMinutes: {
    durationInMinutes: number;
    minutes: number;
  } | null;
  configTaskEffectiveness?: {
    subType?: SubTypeId;
    skuList?: string[];
    challengeId?: string;
    minimumQuantity?: number;
    visionConfigId?: string;
  } | null;
  frequency: FrequencyId | null;
  profile: string;
  submissionStatusCount?: SubmissionStatusCount;
  locked?: boolean;
  isSponsored: boolean;
  sponsoredBy: string | null;
  sponsoredById: string | null;
  imageRecognitionBucket?: ImageRecognitionBucketId | null;
  isCategoryValid: boolean;
}

export interface TaskSurvey {
  id: string;
  templateTitle?: string;
  name: string;
  taskType: TaskTypeId;
  category: CategoryId;
  survey: Survey[];
  priority: number | null;
  durationInMinutes: {
    durationInMinutes: number;
    minutes: number;
  };
  duration: {
    duration: DurationId;
    minutes: number;
  };
  audienceTemplate: ConfigActivityAudienceTemplate;
  frequency: FrequencyId;
  profile: string;
  vendorId: string;
  updatedBy: string;
  updatedById: string;
  createdAt: string;
  createdBy: string;
  createdById: string;
}

export interface TaskPhoto {
  isActive: boolean;
  activityTemplateId: string;
  // templateTitle: string;
  name: string;
  taskType: TaskTypeId;
  category: CategoryId;
  priority: number | null;
  profile: string;
  vendorId: string;
  updatedAt: string;
  updatedBy: string;
  updatedById: string;
  createdAt: string;
  createdBy: string;
  createdById: string;
  imageRecognitionBucket: ImageRecognitionBucketId;
  isSponsored: boolean;
  sponsoredBy: string | null;
  sponsoredById: string | null;
  activityConfigurationPerProfile: ActivityConfigurationPerProfile;
  configTaskEffectiveness?: {
    subType: SubTypeIdPhoto;
    visionConfigId: string;
  };
  visionRuleId: string;
}

export enum ProfileId {
  Bdr = 'BDR',
  NegBdr = 'NEGBDR',
  Merchandiser = 'MERCHANDISER',
}
export interface Duration {
  duration: DurationId;
  minutes: number;
}

export interface Frequency {
  id: string;
  value: FrequencyId;
  isHidden?: boolean;
}

export interface CategoryDeprecated {
  id: string;
  taskTypes: TaskTypeId[];
  profiles: string[];
}

export interface Category {
  id: string;
  title: string;
}

export interface SubCategory {
  id: string;
  categories?: CategoryId[];
}

export interface SubType {
  id: SubTypeId;
  categories?: CategoryId[];
  addMethods: AddMethod[];
  effectivenessFormField: EffectivenessSimpleFormField;
}

export interface ImageRecognitionBucket {
  id: ImageRecognitionBucketId;
  categories?: CategoryId[];
}

export interface ConfigActivityAudience {
  condition?: string; //TODO: (BEESOT-41964) - Change this type by enum or interface
  feature: string;
  operator: string; //TODO:  (BEESOT-41964) - Change this type by enum or interface
  value: any;
  period?: any;
  minValue?: any;
  maxValue?: any;
  requiredPeriod?: any;
  skus?: any;
  metadataIds?: any;
  featureType?: string;
}

export interface ConfigActivityEffectivenessSimple {
  condition?: string; //TODO: (BEESOT-41964) - Change this type by enum or interface
  feature: string;
  operator: string; //TODO:  (BEESOT-41964) - Change this type by enum or interface
  value: any;
  period?: any;
  minValue?: any;
  maxValue?: any;
  requiredPeriod?: any;
  skus?: any;
  metadataIds?: any;
  featureType?: string;
}

export interface ConfigActivityAudienceTemplate {
  rule: {
    feature?: string;
    operator?: string;
    value?: string | number | boolean | string[];
    logicalOperator?: string; //TODO: (BEESOT-41964) - Change this type by enum or interface
    arguments?: ConfigActivityAudience | ConfigActivityAudience[];
    featureType?: string;
    skus?: string[];
    metadataIds?: string[];
  };
}

export interface ConfigTaskEffectivenessTemplate {
  rule: {
    logicalOperator?: string; //TODO: (BEESOT-41964) - Change this type by enum or interface
    arguments: ConfigActivityEffectivenessSimple | ConfigActivityEffectivenessSimple[];
  };
}

export interface ConfigTaskEffectivenessPhoto {
  subType: SubTypeIdPhoto;
  visionConfigId: string;
}

export interface ActivityConfigurationPerProfile {
  profileId?: string;
  message?: string;
  validEffectiveness?: string;
  invalidEffectiveness?: string;
  pendingEffectiveness?: string;
}

export interface PayloadTask {
  templateTitle: string;
  profile: string;
  category: CategoryId;
  isSponsored?: boolean;
  sponsoredBy?: string | null;
  sponsoredById?: string | null;
  name: string;
  textEffectiveTask?: string;
  textNotEffetiveTask?: string;
  textPendingTask?: string;
}

export interface ConfigTaskLocalModel {
  active: boolean;
  country: string;
  endDate: string;
  id: string;
  modelId: string;
  startDate: string;
  vendorGroupId: string;
}
export interface PayloadActivitySimple {
  name: string;
  activityTemplateCategoryId?: string;
  isSponsored?: boolean;
  sponsoredBy?: string | null;
  sponsoredById?: string | null;
  activityConfigurationPerProfile?: ActivityConfigurationPerProfile[];
  activitySubtypeId?: string;
  durationInMinutes?: number;
  frequencyId?: string;
  effectivenessTemplate?: ConfigActivityEffectivenessSimple[] | any;
  audienceTemplate?: ConfigActivityAudience[] | any;
  subTasks?: Survey[];
  activityTypeId?: string;
  tags?: string[];
  campaignId?: string;
  requiredDuration?: boolean;
  activityTemplateGroupId: string;
  modelId?: string;
}

export interface PayloadTaskSimple extends PayloadTask {
  duration: DurationId;
  frequency: string;
  effectivenessTemplate?: ConfigActivityEffectivenessSimple[] | any;
  audienceTemplate?: ConfigActivityAudience[] | any;
}
export interface PayloadActivitySurvey extends PayloadActivitySimple {
  durationInMinutes?: number;
  frequency?: string;
  survey: Survey[];
}

export interface PayloadActivityPhoto extends PayloadActivitySimple {
  imageRecognitionBucket?: ImageRecognitionBucketId;
  configTaskEffectiveness?: ConfigTaskEffectivenessPhoto;
  effectivenessTemplate?: ConfigActivityEffectivenessSimple[] | any;
  visionRuleId: string;
  requiredDuration?: boolean;
}
export interface Survey {
  id: string;
  title: string;
  isRequired: boolean;
  responseType: SurveyQuestionResponseId;
  options?: SubTasksOptions[];
}

export interface SubTasksOptions {
  id: string;
  value: string;
  jump?: SubTaskOptionsConditional;
}

export interface SubTaskOptionsConditional {
  position?: number;
  id?: string;
}

export interface TaskState {
  availableTaskTypes: TaskType[];
  availableCategoriesDeprecated: CategoryDeprecated[];
  availableCategories: Category[];
  availableSubTypesDeprecated: SubType[];
  availableSubTypes: SubType[];
  availableImageRecognitionBucketsDeprecated: ImageRecognitionBucket[];
  availableImageRecognitionBuckets: ImageRecognitionBucket[];
  initialListFilters: ListFilters;
  listFilters: ListFilters;
}

export interface ListFilters {
  isSponsored?: boolean;
  imageRecognition?: ImageRecognitionBucketId;
}

export interface UpdateAndSyncTaskPriority {
  templateId: string;
  currentPriority: number | null;
  newPriority: number;
  profile?: string;
  profileId: string;
  isSponsored: boolean;
  imageRecognitionBucket: ImageRecognitionBucketId;
  activityTemplateGroupId: string;
}

export interface OnSuccessPriorization {
  activityId: string;
  oldPriority: number;
  newPriority: number;
  method: TriggerType;
}

export interface CreateActivitySimpleResponse {
  id: string;
}

export interface CreateTaskPhotoResponse {
  id: string;
}

export interface CreateTaskCancellationResponse {
  TaskCancellationRequestId: string;
}

export interface TaskFilterOption {
  id: TaskFilterType;
  labelId: TaskFilterLabelId;
}

export interface UpdateActivityResponse {
  id: string;
}
export interface UpdateTaskResponse {
  id: string;
}

export type CategoryOptimizelyEntry = [CategoryId, Category];
export interface Profile {
  id: string;
  title: string;
}
export interface UserState {
  profile: Profile;
  isMultiProfile: boolean;
  availableProfiles: Profile[];
  vendorId: string;
  sessionId: string;
  country: string;
  groups: { groups: string }[];
  metadata: UserMetadata;
}
