import { useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { getCategories } from '../../api/services/frontline';
import { QUERY_KEY_ACTIVITY_CATEGORIES } from '../../consts/query';
import { ActivityCategory, ActivityCategoryList } from '../../models/activity';
import { setAvailableActivityCategories } from '../../redux/slices/activitySlice';
import useAppDispatch from '../useAppDispatch';
import useGroupData from '../useContext/useGroupData/useGroupData';

function useActivityCategories(): {
  isSuccess: boolean;
  isError: boolean;
} {
  const dispatch = useAppDispatch();
  const { groupData } = useGroupData();
  const { vendorGroupId } = groupData;

  const { isError, isSuccess } = useQuery<AxiosResponse<ActivityCategoryList>, AxiosError>({
    queryKey: [QUERY_KEY_ACTIVITY_CATEGORIES, vendorGroupId],
    queryFn: async ({ signal }) => {
      const categoriesResponse = await getCategories({ signal });

      const availableCategories = formatAvailableCategories({
        categoriesData: categoriesResponse.data.content,
      });
      dispatch(setAvailableActivityCategories(availableCategories));

      return categoriesResponse;
    },
  });

  function formatAvailableCategories({ categoriesData }: { categoriesData: ActivityCategory[] }) {
    const availableCategories = categoriesData.map(
      ({ category, activityTemplateCategoryId, title }) => {
        return {
          id: activityTemplateCategoryId,
          title,
          category,
        };
      }
    );

    return availableCategories;
  }

  return {
    isSuccess,
    isError,
  };
}

export default useActivityCategories;
