import { useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { getActivityFeatures } from '../../api/services/frontline';
import { QUERY_KEY_ACTIVITY_FEATURES } from '../../consts/query';
import { FeatureList } from '../../models/activity';
import { setActivityFeatureList } from '../../redux/slices/activitySlice';
import useAppDispatch from '../useAppDispatch/useAppDispatch';
import useGroupData from '../useContext/useGroupData/useGroupData';

function useActivityFeatures(): { isSuccess: boolean } {
  const dispatch = useAppDispatch();

  const { groupData } = useGroupData();
  const { vendorGroupId } = groupData;

  const { data: dataActivityFeature } = useQuery<AxiosResponse<FeatureList>, AxiosError>(
    [QUERY_KEY_ACTIVITY_FEATURES, vendorGroupId],
    ({ signal }) => getActivityFeatures({ signal })
  );

  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    if (dataActivityFeature && !isSuccess) {
      const featureList = dataActivityFeature?.data?.content;

      dispatch(setActivityFeatureList(featureList));
      setIsSuccess(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataActivityFeature]);

  return {
    isSuccess,
  };
}

export default useActivityFeatures;
