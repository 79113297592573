/* eslint-disable prefer-destructuring */
import { useUserMetadata } from 'admin-portal-shared-services';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import useGetGroups from '../../../api/services/groups/hooks/useGetGroups';
import { EMPTY_VALUE } from '../../../consts/user';
import { GetGroupDetails } from '../../../models/group';
import { GroupData, TGroupDataContext } from './useGroupData.d';

const initialUserGroupData: GroupData = {
  vendorGroupId: '',
  organization: { name: '', id: '', childGroups: [] },
  seller: { name: '', id: '' },
  vendors: [],
  country: '',
  error: null,
};

export const GroupDataContext = createContext<TGroupDataContext>({
  groupData: initialUserGroupData,
  handleChangeUserGroupInfo: (groupInfo: Partial<GroupData>) => ({ groupInfo }),
  groups: [],
});

export const GroupDataProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const [groupData, setGroupData] = useState<GroupData>(initialUserGroupData);
  const [isLoading, setIsLoading] = useState<boolean>(!!groupData.vendorGroupId);

  const { groups: totalGroups, isLoading: isGroupLoading } = useGetGroups();

  const { data: metadata, isLoading: isLoadingMetadata } = useUserMetadata();

  const groupsWithoutRoot: GetGroupDetails[] = useMemo(() => {
    const firstNode = totalGroups?.content[0];
    if (firstNode?.parentGroupId === 'root') return firstNode.childGroups;
    return totalGroups?.content;
  }, [totalGroups?.content]);

  const handleChangeUserGroupInfo = useCallback((groupInfo: Partial<GroupData>) => {
    const { country, error, organization, seller, type, vendorGroupId, vendors } = groupInfo;

    const groupInfoCountry = country || seller?.country || organization?.country;
    const groupInfoId = vendorGroupId || seller?.id || organization?.id;
    const groupIdVendors = vendors || seller?.vendors || organization?.vendors;

    localStorage.setItem('group-info', JSON.stringify(groupInfo));

    setGroupData((prev) => ({
      vendorGroupId: groupInfoId,
      organization: organization ?? EMPTY_VALUE,
      seller: seller ?? EMPTY_VALUE,
      country: groupInfoCountry ?? prev.country,
      vendors: groupIdVendors ?? prev.vendors,
      error: error ?? prev.error,
      type,
    }));
  }, []);

  const handleSelectNode = useCallback(
    (nodeId: string) => {
      const userNode =
        groupsWithoutRoot.find((group) => group.id === nodeId) ?? groupsWithoutRoot[0];
      handleChangeUserGroupInfo({ organization: userNode });
    },
    [handleChangeUserGroupInfo, groupsWithoutRoot]
  );

  const handleSelectFirstLeafFromNode = useCallback(() => {
    const firstChildrenFromNode = groupData.organization.childGroups?.[0] ?? EMPTY_VALUE;
    handleChangeUserGroupInfo({
      organization: groupData.organization,
      seller: firstChildrenFromNode,
    });
  }, [groupData, handleChangeUserGroupInfo]);

  const findChildGroup = (childGroups, groupInfo) => {    
    if (!!groupInfo && !!childGroups && Array.isArray(childGroups) && childGroups?.length > 0) {
      return childGroups?.filter((childGroup) => childGroup.id === groupInfo.id)[0];
    } else if (!!childGroups && Array.isArray(childGroups) && childGroups?.length > 0) {
      return childGroups[0];
    } else {
      return EMPTY_VALUE;
    }
  }

  useEffect(() => {
    const root = totalGroups?.content[0];

    if (root) {
      const groupInfoLocalStorage = JSON.parse(localStorage.getItem('group-info'));
      const organization = findChildGroup(root.childGroups, groupInfoLocalStorage?.organization);
      const seller = findChildGroup(organization?.childGroups, groupInfoLocalStorage?.seller);

      handleChangeUserGroupInfo(
        groupInfoLocalStorage
          ? { ...groupInfoLocalStorage, organization, seller }
          : { ...initialUserGroupData, organization, seller }
      );
    }
  }, [handleChangeUserGroupInfo, totalGroups?.content]);

  useEffect(() => {
    const isReadyMetaData = !isLoadingMetadata && metadata.nodes['force'].length;
    const isReadyGroupData = !isGroupLoading && totalGroups?.content;
    const isFirstTime = !groupData.vendorGroupId;

    if (isReadyMetaData && isReadyGroupData && isFirstTime) {
      handleSelectNode(metadata.nodes['force'][0]);
    }
  }, [
    isGroupLoading,
    groupData.vendorGroupId,
    totalGroups?.content,
    metadata,
    isLoadingMetadata,
    handleSelectNode,
    groupData,
  ]);

  // TO DO: Add logic to reload the optmizely config without force this re-render
  useEffect(() => {
    if (groupData.vendorGroupId) {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 0);
    }
  }, [groupData.vendorGroupId]);

  const contextValue = useMemo(() => {
    return {
      groupData,
      groups: groupsWithoutRoot,
      handleChangeUserGroupInfo,
      handleSelectFirstLeafFromNode,
    };
  }, [groupData, handleChangeUserGroupInfo, handleSelectFirstLeafFromNode, groupsWithoutRoot]);

  if (isLoading || isLoadingMetadata) {
    return <div>Loading...</div>;
  }

  return <GroupDataContext.Provider value={contextValue}>{children}</GroupDataContext.Provider>;
};

const useGroupData: () => TGroupDataContext = () => useContext(GroupDataContext);

export default useGroupData;
