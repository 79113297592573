import MessageMap from '../../../../i18n/i18n.d';

const enUS: MessageMap = {
  title: {
    page: {
      taskGroupsList: 'Task groups',
      taskGroupCreate: 'Create task group',
      taskGroupUpdate: 'Edit task group',
      taskGroupView: 'View task group',
    },
  },
  button: {
    taskGroupButtonCreate: 'Create group',
    taskGroupButtonUpdate: 'Edit group',
    taskGroupButtonCancel: 'Go back',
    editTaskGroup: 'Edit group',
    viewGroup: 'View group',
  },
  fields: {
    label: {
      taskList: {
        selectProfile: 'Profile',
        taskGroupNextSync: 'Changes made will take effect on the next sync.',
        priority: 'Priority: ',
        localModel: 'Local model',
      },
      taskGroupSelect: 'Task group',
    },
    taskGroupForm: {
      label: {
        profile: 'Profile',
        name: 'Name',
        description: 'Description',
        maximumTasks: 'Maximum tasks in this group',
        isLocalModel: 'Local Model Tasks',
      },
    },
    placeholder: {
      profile: 'Select the profile',
      name: 'Enter group name',
      description: 'Enter the group description',
      maximumTasks: 'Ex. 20',
      taskGroupPriority: 'Ex. 3',
    },
  },
  message: {
    error: {
      taskGroupsNotFound: 'No task groups created yet. Start creating then on Create group.',
    },
    info: {
      effectivenessMultipleConditions:
        'The selected feature is of the coverage type, therefore it cannot be paired with multiple conditions.',
      frequencyDisabled: 'The selected feature requires a fixed default value for frequency.',
    },
  },
  toast: {
    taskGroupError: 'Error loading tas group. Please try again later.',
    error: {
      taskGroupsPriority: 'Unable to pdate the task group priority. Try again later.',
    },
    success: {
      taskGroupsPriority: 'The priority of {total} task groups has been adjusted.',
    },
  },
};

export default enUS;
