import { useIsFetching, useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';
import { useEffectOnce } from 'usehooks-ts';
import useActivityCategories from '../../hooks/useActivityCategories';
import useActivityFeatures from '../../hooks/useActivityFeatures';
import useAppLanguage from '../../hooks/useAppLanguage';
import useAppSelector from '../../hooks/useAppSelector/useAppSelector';
import useAppUserMetadata from '../../hooks/useAppUserMetadata';
import useMultiProfileFlag from '../../hooks/useMultiProfileFlag';
import useUserActivityScopes from '../../hooks/useUserActivityScopes';
import useUserTaskScopes from '../../hooks/useUserTaskScopes';
import { UserState } from '../../models/user';
import PageError from '../../pages/PageError';
import PageLoading from '../../pages/PageLoading';

interface UserInfoProviderProps {
  children?: JSX.Element;
}

const UserInfoProvider = ({ children }: UserInfoProviderProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const isFetching = useIsFetching();
  const queryClient = useQueryClient();

  useEffectOnce(() => {
    setTimeout(() => {
      if (isFetching > 0) {
        queryClient.invalidateQueries();
      }
    }, 10000);
  });

  const { isSuccessMetadata, hasErrorMetadata, vendorGroupId, mutateUserMetadata, metadata } =
    useAppUserMetadata();
  const { isSuccessMultiProfileFlag, isMultiProfileLoading } = useMultiProfileFlag();
  const preferredLanguage = metadata?.profile?.preferredLanguage;
  useAppLanguage({ preferredLanguage });
  const { isSuccess: isSuccessUserActivityScopes } = useUserActivityScopes();
  const { isSuccess: isSuccessUserTaskScopes } = useUserTaskScopes();
  const { isSuccess: isSuccessActivityFeatures } = useActivityFeatures();
  const { isSuccess: isSuccessActivityCategories, isError: isErrorActivityCategories } =
    useActivityCategories();
  const { availableProfiles } = useAppSelector<UserState>((state) => state.user);

  const doesNotHaveVendorId = isSuccessMetadata && !vendorGroupId;
  const doesNotHaveProfile = !!availableProfiles && availableProfiles?.length === 0;

  const isUserInfoReady =
    isSuccessMetadata &&
    isSuccessMultiProfileFlag &&
    isSuccessUserActivityScopes &&
    isSuccessUserTaskScopes &&
    isSuccessActivityCategories &&
    isSuccessActivityFeatures &&
    !isMultiProfileLoading;

  const hasErrorUserInfo = hasErrorMetadata || doesNotHaveVendorId || doesNotHaveProfile;

  if (!isUserInfoReady && !hasErrorUserInfo) {
    return <PageLoading />;
  }

  else if (hasErrorUserInfo && hasErrorUserInfo) {
    let errorMessageId = 'message.error.requestUserInfo';
    
    if (doesNotHaveVendorId) {
      errorMessageId = 'message.error.userWithoutVendorId';
    }
    else if(doesNotHaveProfile) 
      errorMessageId = 'message.error.userWithoutProfile';

    return (
      <PageError
        error={{
          message: formatMessage({ id: errorMessageId }),
        }}
        onRetry={() => mutateUserMetadata()}
      />
    );
  }

  return children;
};

export default UserInfoProvider;
